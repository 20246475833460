import { createTheme } from '@material-ui/core/styles';
const Theme = createTheme({
    palette: {
        primary: {
            main: '#ff9800',
        },
        // secondary: {
        //     main: '#ED1B2D',
        // },
    },

   
});

export default Theme;